
<template>
  <div class="SampleNotif">
    <notification-popup  
    :dialog="success"
    contentMsg="Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio vero laboriosam totam quaerat, aliquam accusantium hic, adipisci facilis nostrum aspernatur porro exercitationem facere, sint in unde tempore eius excepturi pariatur.?"
    headerMsg="Success Transaction"
    nameBtnNo="No"
    nameBtnYes="Yes"
    colorIcon = "success"
    typeModal="transaction"
    Icon="mdi-check-circle"
    :onHandlerYes="buttonOke"/>

    <notification-popup 
    :dialog="Failed"
    contentMsg="Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio vero laboriosam totam quaerat, aliquam accusantium hic, adipisci facilis nostrum aspernatur porro exercitationem facere, sint in unde tempore eius excepturi pariatur.?"
    headerMsg="Failed Transaction"
    nameBtnNo="No"
    nameBtnYes="Yes"
    colorIcon = "danger"
    typeModal="transaction"
    Icon="mdi-close-circle"
    :onHandlerYes="buttonOke"/>

    <notification-popup 
    :dialog="Dialog"
    contentMsg="this is message dialog?"
    headerMsg="Dialog"
    nameBtnNo="No"
    nameBtnYes="Yes"
    colorIcon = "danger"
    :onHandlerYes="buttonOke"/>

    <notification-popup 
    :dialog="Card"
    contentMsg="this is message dialog?"
    headerMsg="Dialog"
    nameBtnNo="No"
    nameBtnYes="Yes"
    colorIcon = "danger"
    typeModal="card"
    titleheadlineCard="Title"
    contentHeadlineCard="Content message ......."
    :onHandlerYes="buttonOke"/>


    <AcButton name="Success" :onClickHandler="clickSuccess"></AcButton>
    <AcButton name="Failed" :onClickHandler="clickFailed"></AcButton>
    <AcButton name="Dialog" :onClickHandler="clickDialog" ></AcButton>
    <AcButton name="Card" :onClickHandler="clickCard"></AcButton>
  </div>
</template>

<script>
import NotificationPopup from '@/components/NotificationPopup.vue'
import AcButton from '@/components/AcButton.vue'

export default {
  name: "SampleNotif",
  components: {NotificationPopup, AcButton},
  data() {
    return {
      Card:false,
      Dialog:false,
      Failed:false,
      success:false,
      drawer: null,
      lang: ["id", "en"],
    };
  },
  methods:{
    clickSuccess(){
        this.success = true
    },
    clickFailed(){
        this.Failed = true
    },
    clickDialog(){
        this.Dialog = true
    },
    clickCard(){
        this.Card = true

    },
    buttonOke(){
      this.success = false
      this.Failed = false
      this.Dialog = false
      this.Card = false
    }
  },
  mounted(){
  }
};
</script>